import React from 'react';
import { Typography } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { styled } from "@mui/material/styles";

import smartMeterCADIcon from '../../images/smart-meter-cad-icon.svg';
import dataIngestionDiagram from '../../images/data-ingestion-diagram.svg';
import metadataIngestionDiagram from '../../images/metadata-ingestion-diagram.svg';
import requestingDisaggregationResultsDiagram from '../../images/requesting-disaggregation-results-diagram.svg';

import Layout from '../../components/Layout';
import {
  Title,
  Button as CustomButton,
  Text as CustomText
} from '../../mui-elements/shared';
import { SEO } from '../../components/SEO';

const Button = styled(CustomButton)({
  maxWidth: 500,
  marginTop: '1rem',
  alignSelf: 'center',
}) as typeof CustomButton;

const Text = styled(CustomText)({
  textAlign: 'justify',
}) as typeof CustomText;

import {
  Header,
  Section,
  WhiteSection,
  GreySection,
  Diagram,
} from '../../style/pages/integrations';

export default function SmartMetersCAD() {
  return (
    <Layout>
      <Header>
        <div>
          <img src={smartMeterCADIcon} alt="Smart Meters and CAD icon" />
          <Typography component="h1" variant="h1">
            Smart Meters + CAD
          </Typography>
        </div>
      </Header>
      <WhiteSection>
        <Section>
          <Title component="h2" variant="h2">
            How It Works
          </Title>

          <Text my={1}>
            Voltaware has created a platform to help our customers integrate 
            Smart Meter + CAD high resolution data and retrieve AI-based 
            energy insights. 
          </Text>
          <Text my={1}>
            Voltaware will receive the data, in an anonymised way, to provide 
            you with daily and monthly disaggregation-based insights to 
            integrate back into your customer-facing applications.
          </Text>
        </Section>
      </WhiteSection>
      <GreySection>
        <Section>
          <Title component="h2" variant="h2">
            Data Ingestion
          </Title>
          <Diagram src={dataIngestionDiagram} />
          <Text my={1}>
            In order to integrate your data with the Voltaware Platform, please 
            create an event propagator to connect to our message broker and 
            send meters’ data packets to the Voltaware backend.
          </Text>
          <Text my={1}>
            The smart meter + CAD data can be sent on a daily, weekly or 
            monthly basis. 
          </Text>
          <Button
            fullWidth={true}
            variant="outlined"
            role="link"
            href="https://docs.voltaware.com/mqtt-smart-meter-cad.html"
            target="_blank"
          >
            Data Ingestion Documentation
          </Button>
        </Section>
      </GreySection>
      <WhiteSection>
        <Section>
          <Title component="h2" variant="h2">
            Metadata Ingestion
          </Title>
          <Diagram src={metadataIngestionDiagram} />
          <Text my={1}>
            In order to optimize our results’ accuracy, Voltaware needs to 
            receive metadata from your backend. This metadata includes users’ 
            information such as: 
          </Text>
          <ul>
            <li>
              <ArrowRightIcon fontSize="large" />
              <Text>Property type</Text>
            </li>
            <li>
              <ArrowRightIcon fontSize="large" />
              <Text>House size</Text>
            </li>
            <li>
              <ArrowRightIcon fontSize="large" />
              <Text>Number of bedrooms</Text>
            </li>
            <li>
              <ArrowRightIcon fontSize="large" />
              <Text>Number of people</Text>
            </li>
            <li>
              <ArrowRightIcon fontSize="large" />
              <Text>Home type</Text>
            </li>
            <li>
              <ArrowRightIcon fontSize="large" />
              <Text>Whether the user generates power or not</Text>
            </li>
            <li>
              <ArrowRightIcon fontSize="large" />
              <Text>Power Generation source</Text>
            </li>
            <li>
              <ArrowRightIcon fontSize="large" />
              <Text>List of appliances </Text>
            </li>
          </ul>
          <Text my={1}>
            Please send this information through our HTTP Services API.
          </Text>
          <Button
            fullWidth={true}
            variant="outlined"
            role="link"
            href="https://docs.voltaware.com/http-services.html#appliances"
            target="_blank"
          >
            Metadata Ingestion Documentation
          </Button>
        </Section>
      </WhiteSection>
      <GreySection>
        <Section>
          <Title component="h2" variant="h2">
            Requesting Disaggregation Results
          </Title>
          <Diagram src={requestingDisaggregationResultsDiagram} />
          <Text my={1}>
            To provide you with a list of appliances or appliance groups used 
            by each client, please request it using our HTTP Services API and 
            store them in your database. You can then use these results to 
            create your own mobile app, web app, smart bill or any other 
            customer facing product.
          </Text>
          <Button
            fullWidth={true}
            variant="outlined"
            role="link"
            href="https://docs.voltaware.com/http-services.html#disaggregation"
            target="_blank"
          >
            Disaggregation Results Documentation
          </Button>
        </Section>
      </GreySection>
    </Layout>
  );
}

export const Head = () => (
  <SEO />
)
