import styled from "styled-components";

import sensorBanner from '../../images/sensor-banner.png';

export const Header = styled.div`
  background-image: url(${sensorBanner});
  padding: 3rem 0;
  padding-left: 16rem;
  width: 100%;

  * {
    transition: all .33s;
  }

  & > div {
    display: flex;
    align-items: center;
    max-width: 1200px;
    width: 100%;
  }

  h1 {
    color: ${({ theme }) => theme.colors.white};
    font-size: 3.5rem;

    @media (max-width: 1200px) {
      font-size: 2.8rem;
    }

    @media (max-width: 900px) {
        font-size: 2.4rem;
    }

    @media (max-width: 600px) {
        font-size: 2rem;
    }
  }
  
  img {
    width: 100%;
    max-width: 200px;
    margin-right: 2rem;

    @media (max-width: 1200px) {
      max-width: 140px;
    }

    @media (max-width: 900px) {
      max-width: 110px;
    }

    @media (max-width: 600px) {
      max-width: 80px;
      margin-right: 0.5rem;
    }
  }

  @media (max-width: 1536px) {
    padding-left: 12rem;
  }

  @media (max-width: 1200px) {
    padding-left: 10rem;
  }

  @media (max-width: 900px) {
    padding-left: 6rem;
  }

  @media (max-width: 600px) {
    align-items: flex-start;
  }
`;

export const Section = styled.div`
  max-width: 900px;
  display: flex;
  flex-direction: column;
  padding: 2rem 2rem 3rem;
  margin: 0 auto;

  p {
    @media (max-width: 900px) {
      font-size: 1.2rem;
    }
  }

  ul {
    list-style: none;
    padding-left: 15px;

    li {
      display: flex;
      align-items: center;
      padding: 0.2rem 0;
      
      p {
        padding-left: 5px;
      }

      svg {
        color: ${({ theme }) => theme.colors.text};
        font-size: 1.8rem;
      }
    }
  }
`;

export const WhiteSection = styled.article`
  background-color: ${({ theme }) => theme.colors.white};
`;

export const GreySection = styled.article`
  background-color: ${({ theme }) => theme.colors.white_darker};
`;

export const Diagram = styled.img`
  max-width: 800px;
  width: 100%;
  margin: 2rem auto 3rem;
`;
